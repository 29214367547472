// Create a card component for the direct flight search results using tailwindcss
import { React, useState, useEffect } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file



const FlightSearchDirectCard = ({travelClass, setSearchResults}) => {

    const ALL = ['SIN', 'LAX', 'DEL', 'DPS', 'DFW', 'HND', 'CGK', 'HKG', 'NAN', 'YVR', 'JNB', 'BKK', 'HNL', 'MNL', 'NOU', 'TBU', 'APW', 'BLR', 'SCL', 'ICN', 'SFO', 'JFK', 'FCO', 'PVG', 'POM', 'LHR', 'ROM', 'MEL', 'SYD', 'BNE', 'PER'];
    const DAYS = [1, 3, 5, 7, 14, 21, 28, 90, 180, 365];
    // eslint-disable-next-line 
    const COMBOS = {'SIN': ['MEL', 'SYD', 'BNE', 'PER'], 'LAX': ['MEL', 'SYD', 'BNE'], 'DEL': ['MEL'], 'DPS': ['MEL', 'SYD'], 'DFW': ['MEL', 'SYD'], 'HND': ['MEL', 'SYD', 'BNE'], 'CGK': ['MEL', 'SYD'], 'HKG': ['MEL', 'SYD'], 'NAN': ['SYD'], 'YVR': ['SYD'], 'JNB': ['SYD', 'PER'], 'BKK': ['SYD'], 'HNL': ['SYD'], 'MNL': ['SYD'], 'NOU': ['SYD', 'BNE'], 'TBU': ['SYD'], 'APW': ['SYD'], 'BLR': ['SYD'], 'SCL': ['SYD'], 'ICN': ['SYD'], 'SFO': ['SYD'], 'JFK': ['SYD'], 'FCO': ['SYD'], 'PVG': ['SYD'], 'POM': ['BNE'], 'LHR': ['PER'], 'ROM': ['PER'], 'MEL': ['SIN', 'LAX', 'DEL', 'DPS', 'DFW', 'HND', 'CGK', 'HKG'], 'SYD': ['LAX', 'SIN', 'NAN', 'YVR', 'JNB', 'BKK', 'DFW', 'HNL', 'MNL', 'DPS', 'CGK', 'NOU', 'TBU', 'APW', 'HND', 'BLR', 'SCL', 'ICN', 'HKG', 'SFO', 'JFK', 'FCO', 'PVG'], 'BNE': ['SIN', 'LAX', 'POM', 'NOU', 'HND'], 'PER': ['SIN', 'LHR', 'JNB', 'ROM']}
    // eslint-disable-next-line 
    const ALL_REGIONS = ['Asia', 'Australia', 'Europe', 'North America', 'South America', 'Africa', 'Oceania'];
    const REGION_DESTINATIONS = {'Asia': ['SIN', 'DEL', 'DPS', 'HND', 'CGK', 'BKK', 'MNL', 'ICN', 'PVG', 'BLR', 'HKG'], 'Australia': ['MEL', 'SYD', 'BNE', 'PER'], 'Europe': ['LHR', 'FCO', 'ROM'], 'North America': ['LAX', 'DFW', 'YVR', 'HNL', 'SFO', 'JFK'], 'South America': ['SCL'], 'Africa': ['JNB'], 'Oceania': ['NAN', 'TBU', 'APW']}

    const [availableDestinations, setAvailableDestinations] = useState([]);

    const [origin, setOrigin] = useState('');
    const [destination, setDestination] = useState('');
    const [date, setDate] = useState('');
    const [days, setDays] = useState(7);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [loading, setLoading] = useState(false);
    const [searchType, setSearchType] = useState('direct');
    const [origins, setOrigins] = useState({});
    const [destinations, setDestinations] = useState({});

    function setDateRange(selectedDate, days) {
        // start date is selectedDate minus the number of days
        let sDate = new Date(selectedDate);
        sDate.setDate(sDate.getDate() - days);
        setStartDate(sDate);
        // end date is selectedDate plus the number of days
        let eDate = new Date(selectedDate);
        eDate.setDate(eDate.getDate() + days);
        setEndDate(eDate);
        setDate(selectedDate);
        setDays(days);
    }
    // call the API to get the results
    function getFlights() {
        setLoading(true);
        setSearchResults([]);
        // convert start and end dates to ddmmyy format, remove the first 2 characters of the year
        const start = startDate.getDate().toString().padStart(2, '0') + (startDate.getMonth() + 1).toString().padStart(2, '0') + startDate.getFullYear().toString().substring(2);
        // remove the first 2 characters of the year
        const end = endDate.getDate().toString().padStart(2, '0') + (endDate.getMonth() + 1).toString().padStart(2, '0') + endDate.getFullYear().toString().substring(2);

        const url = "https://43knpyxjxd3ahfaciq73zkjbee0sftfv.lambda-url.ap-southeast-2.on.aws/?origin=" + origin + "&destination=" + destination + "&startDate=" + start + "&endDate=" + end + "&req=flights&serviceClass=BUS";
        var password = Math.floor(Date.now() / 1000);
        // replace the first digit with 8
        password = password.toString().replace(/^./, '8');

        fetch((url + "&password=" + password), {
            method: 'GET',
            headers: {}
        })
        .then(response => response.json())
        .then(data => {
            if(data.length > 0) {
                setSearchResults(data);
            } else {
                setSearchResults([
                    {
                        "Flight": "-------",
                        "Date": "|No flights found!",
                    }
                ]);
            }
            setLoading(false);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    function getFlightRegion(){
        setLoading(true);
        var trueOrigins = [];
        var trueDestinations = [];
        // store the routeCombos in a string, separated by commas
        var routeCombinations = "";
        // for each origin in origins, if it is set to true 
        for (const [key, value] of Object.entries(origins)) {
            if(value){
                trueOrigins.push(key);
            }
        }
        // for each destination in destinations, if it is set to true
        for (const [key, value] of Object.entries(destinations)) {
            if(value){
                trueDestinations.push(key);
            }
        }
        // for each origin in trueOrigins, get the destinations that are available
        for (var i = 0; i < trueOrigins.length; i++){
            var city = trueOrigins[i];
            var possibleDestinations = COMBOS[city];
            // for each destination in trueDestinations, if it is in possibleDestinations, add it to routeCombinations
            for (var j = 0; j < trueDestinations.length; j++){
                var d = trueDestinations[j];
                if(possibleDestinations.includes(d)){
                    routeCombinations += city + d + "8";
                }
            }
        }

        // if there are no route combinations, return an empty array
        if (routeCombinations.length === 0){
            return [];
        }
        else {
            // get the flights from the API
            setLoading(true);
            setSearchResults([]);
            // convert start and end dates to ddmmyy format, remove the first 2 characters of the year
            const start = startDate.getDate().toString().padStart(2, '0') + (startDate.getMonth() + 1).toString().padStart(2, '0') + startDate.getFullYear().toString().substring(2);
            // remove the first 2 characters of the year
            const end = endDate.getDate().toString().padStart(2, '0') + (endDate.getMonth() + 1).toString().padStart(2, '0') + endDate.getFullYear().toString().substring(2);
            
            const url = "https://43knpyxjxd3ahfaciq73zkjbee0sftfv.lambda-url.ap-southeast-2.on.aws/?origin=" + routeCombinations + "&destination=nada&startDate=" + start + "&endDate=" + end + "&req=flightsRegions&serviceClass=BUS";
            var password = Math.floor(Date.now() / 1000);
            // replace the first digit with 8
            password = password.toString().replace(/^./, '8');

            fetch((url + "&password=" + password), {
                method: 'GET',
                headers: {}
            })
            .then(response => response.json())
            .then(data => {
                if(data.length > 0) {
                    setSearchResults(data);
                } else {
                    setSearchResults([
                        {
                            "Flight": "-------",
                            "Date": "|No flights found!",
                        }
                    ]);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        }




        
    }

    function setRegions(oORd, region) {
        if (oORd === 'origin') {
            setOrigin(region);
            // set origins as the destinations of the selected region with a true/false value
            const newOrigins = {};
            REGION_DESTINATIONS[region].forEach((dest) => {
                newOrigins[dest] = true;
            });
            setOrigins(newOrigins);
        } else {
            setDestination(region);
            // set destinations as the destinations of the selected region with a true/false value
            const newDestinations = {};
            REGION_DESTINATIONS[region].forEach((dest) => {
                newDestinations[dest] = true;
            });
            setDestinations(newDestinations);
        }
    }

    function modifyRegions(oORd, city) {
        if (oORd === 'origin') {
            // if true, set to false, if false, set to true
            if (origins[city]) {
                setOrigins({...origins, [city]: false});
            } else {
                setOrigins({...origins, [city]: true});
            }
            
        } else {
            // if true, set to false, if false, set to true
            if (destinations[city]) {
                setDestinations({...destinations, [city]: false});
            } else {
                setDestinations({...destinations, [city]: true});
            }
        }
    }


    // get the available destinations based on the origin
    useEffect(() => {
        setLoading(true);
        // check if origin is all capital letters
        if (origin !== '' && origin === origin.toUpperCase()) {
            setAvailableDestinations(COMBOS[origin]);
            setLoading(false);
        }
        // if origin is not 'Australia
        else if (origin !== '' && origin !== 'Australia') {
            setAvailableDestinations(['Australia']);
            setLoading(false);
        }
        // if origin is 'Australia'
        else if (origin === 'Australia') {
            // set the available destinations to all regions except 'Australia'
            setAvailableDestinations(ALL_REGIONS.filter(region => region !== 'Australia'));
            setLoading(false);
        }
        // if origin is empty
        else {
            setLoading(false);
        }
    }, [origin, ALL_REGIONS, COMBOS]);

    if (searchType === 'direct' && !loading) {
        return (
        <div class='relative p-2'>
                <div class='relative bg-clip-border p-2 sm:p-1 border-violet-500 rounded-lg grid lg:grid-cols-7 lg:grid-rows-1 border-2 grid-cols-1 grid-rows-7 sm:space-y-2'>
                    <div class=' col-span-1 row-span-1 flex flex-row justify-center items-center'>
                        <span class='text-3xl font-bold'>Flight Search</span>
                    </div>
                    <div class='col-span-1 row-span-1 flex flex-row justify-center items-center'>
                        {/* create a two tabs selector, the two options should be Direct and Region */}
                        <div class='flex-row justify-center items-center grid grid-cols-2 grid-rows-1 space-x-2'>
                            <div class='flex flex-row justify-center items-center col-span-1 row-span-1'>
                                <input type='radio' id='direct' name='flightType' value='direct' onChange={(e) => setSearchType(e.target.value)} checked={searchType === 'direct'}></input>
                                <label for='direct' class='text-lg font-bold'>Direct</label>
                            </div> 
                            <div class='flex flex-row justify-center items-center col-span-1 row-span-1'>
                                <input type='radio' id='region' name='flightType' value='region' onChange={(e) => setSearchType(e.target.value)}></input>
                                <label for='region' class='text-lg font-bold'>Region</label>
                            </div>  
                        </div>
                    </div>
                    <div className="col-span-1 row-span-1">
                        <label className="text-gray-700 text-sm font-bold mb-2 md:mb-0 pr-2" htmlFor="origin">Origin: </label><br />
                        {/* options are from const ALL */}
                        <select className="bg-white shadow border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="origin" onChange={e => setOrigin(e.target.value)} value={origin}>
                            <option value="">Select Origin</option>
                            {ALL.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-span-1 row-span-1">
                        <label className="text-gray-700 text-sm font-bold mb-2 md:mb-0 pr-2" htmlFor="destination">Destination: </label><br />
                        {/* options are from const COMBOS */}
                        <select className="bg-white shadow border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="destination" onChange={e => setDestination(e.target.value)} value={destination}>
                            <option value="">Select Destination</option>
                            {availableDestinations.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-span-1 row-span-1">
                            <label className="text-gray-700 text-sm font-bold mb-2 md:mb-0 pr-2" htmlFor="date">Date: </label><br />
                            <input className="bg-white shadow border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="date" id="startDate" onChange={e => setDateRange(e.target.value, days)} value={date}></input>
                    </div>

                    <div className="col-span-1 row-span-1">
                        <label className="text-gray-700 text-sm font-bold mb-2 md:mb-0 pr-2" htmlFor="days">Number of Days(+/-): </label><br />
                        {/* options are from const DAYS */}
                        <select className="bg-white shadow border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="days" onChange={e => setDateRange(date, Number(e.target.value))} defaultValue={days}>
                            <option value="">Select Days</option>
                            {DAYS.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                    </div>
                    <div class='flex flex-row justify-center items-center col-span-1 row-span-1'>
                        <button class='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded' onClick={getFlights}>Search</button>
                    </div>
                </div> 
            </div>
        )
    }
    else if (searchType === 'region' && !loading) {
        return (
            <div class=' relative p-2'>
                <div class='relative bg-clip-border p-2 sm:p-1 border-violet-500 rounded-lg grid lg:grid-cols-7 lg:grid-rows-1 border-2 grid-cols-1 grid-rows-7'>
                    <div class=' col-span-1 row-span-1 flex flex-row justify-center items-center'>
                        <span class='text-3xl font-bold'>Flight Search</span>
                    </div>
                    <div class='col-span-1 row-span-1 flex flex-row justify-center items-center'>
                        {/* create a two tabs selector, the two options should be Direct and Region */}
                        <div class='flex-row justify-center items-center grid grid-cols-2 grid-rows-1 space-x-2'>
                            <div class='flex flex-row justify-center items-center col-span-1 row-span-1'>
                                <input type='radio' id='direct' name='flightType' value='direct' onChange={(e) => setSearchType(e.target.value)}></input>
                                <label for='direct' class='text-lg font-bold'>Direct</label>
                            </div> 
                            <div class='flex flex-row justify-center items-center col-span-1 row-span-1'>
                                <input type='radio' id='region' name='flightType' value='region' onChange={(e) => setSearchType(e.target.value)} checked={searchType === 'region'}></input>
                                <label for='region' class='text-lg font-bold'>Region</label>
                            </div>  
                        </div>
                    </div>
                    <div className="col-span-1 row-span-1">
                        <label className="text-gray-700 text-sm font-bold mb-2 md:mb-0 pr-2" htmlFor="origin">Origin: </label><br />
                        {/* options are from const ALL */}
                        <select className="bg-white shadow border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="origin" onChange={e => setRegions("origin", e.target.value)} value={origin}>
                            <option value="">Select Region</option>
                            {ALL_REGIONS.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                        {/* once a region is selected, show which cities are in that region in checkboxes below, the options are from origins object which has the keys as the cities and the values as their selection status (true/false) */}
                        <div class = 'grid grid-cols-3 grid-flow-row gap-0'>
                            {Object.keys(origins).map((item, index) => (
                                <div key={index} class="col-span-1 row-span-1">
                                    <input type="checkbox" id={item} name={item} value={item} onChange={e => modifyRegions("origin", e.target.value)} checked={origins[item]}></input>
                                    <label for={item}>{item}</label>
                                </div>
                            ))}
                        </div>
                            
                        
                    </div>
                    <div className="col-span-1 row-span-1">
                        <label className="text-gray-700 text-sm font-bold mb-2 md:mb-0 pr-2" htmlFor="destination">Destination: </label><br />
                        {/* options are from const COMBOS */}
                        <select className="bg-white shadow border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="destination" onChange={e => setRegions("destination", e.target.value)} value={destinations}>
                            <option value="">Select Destination</option>
                            {availableDestinations.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                        {/* once a region is selected, show which cities are in that region in checkboxes below, the options are from destinations object which has the keys as the cities and the values as their selection status (true/false) */}
                        <div className="grid grid-cols-3 grid-flow-row">
                            {Object.keys(destinations).map((item, index) => (
                                <div key={index} className="col-span-1 row-span-1">
                                    <input type="checkbox" id={item} name={item} value={item} onChange={e => modifyRegions("destination", e.target.value)} checked={destinations[item]}></input>
                                    <label for={item}>{item}</label>
                                </div>
                            ))}
                        </div>
                        
                    </div>
                    <div className="col-span-1 row-span-1">
                            <label className="text-gray-700 text-sm font-bold mb-2 md:mb-0 pr-2" htmlFor="date">Date: </label><br />
                            <input className="bg-white shadow border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="date" id="startDate" onChange={e => setDateRange(e.target.value, days)} value={date}></input>
                    </div>

                    <div className="col-span-1 row-span-1">
                        <label className="text-gray-700 text-sm font-bold mb-2 md:mb-0 pr-2" htmlFor="days">Number of Days(+/-): </label><br />
                        {/* options are from const DAYS */}
                        <select className="bg-white shadow border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="days" onChange={e => setDateRange(date, Number(e.target.value))} defaultValue={days}>
                            <option value="">Select Days</option>
                            {DAYS.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                    </div>
                    <div class='flex flex-row justify-center items-center col-span-1 row-span-1'>
                        <button class='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded' onClick={getFlightRegion}>Search</button>
                    </div>
                </div> 
            </div>
        )
    }
    else if (loading) {
        return (
            <div class=' relative p-2'>
                <div class=' relative bg-clip-border p-0 sm:p-1 border-violet-300 rounded-lg grid grid-cols-8 grid-rows-1'>
                    <div class=' col-span-8 row-span-1 rounded-t-lg'>
                        <span class='text-3xl font-extrabold tracking-tight text-gray-900'>Loading...</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default FlightSearchDirectCard;
