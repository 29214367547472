import { React, useState, useEffect } from 'react';

const FlightMetaCard = ({travelClass, setTravelClass}) => {

    const [timeStamp, setTimeStamp] = useState('');
    const [loading, setLoading] = useState(true);

    const [newFlights, setNewFlights] = useState([]);
    const [deletedFlights, setDeletedFlights] = useState([]);

    const [showModal, setShowModal] = useState(false);

    // load data from API
    useEffect(() => {
        const url = "https://43knpyxjxd3ahfaciq73zkjbee0sftfv.lambda-url.ap-southeast-2.on.aws/?origin=MEL&destination=LAX&startDate=110123&endDate=120123&req=meta&serviceClass=" + travelClass;
        // password is current int unix time
        var password = Math.floor(Date.now() / 1000);
        // replace the first digit with 8
        password = password.toString().replace(/^./, '8');

        console.log("password: " + password);

        fetch((url + "&password=" + password), {
            method: 'GET',
            headers: {}
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            setTimeStamp(data.TimeStamp);
            setNewFlights(data.NewFlights);
            setDeletedFlights(data.DeletedFlights);
            setLoading(false);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }
    , [travelClass]);

    function timeConverter(UNIX_timestamp){
        var a = new Date(UNIX_timestamp * 1000);
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var ampm = "AM"
        // if hour is 1 digit, add a 0 in front
        if (hour < 10) {
            hour = '0' + hour;
        }
        // if hour is greater than 12, subtract 12 and add PM
        if (hour > 12) {
            hour = hour - 12;
            ampm = "PM"
        }
        // if hour is 12, add PM
        if (hour === 12) {
            ampm = "PM"
        }
        // if hour is 0, set to 12
        if (hour === 0) {
            hour = 12;
        }
        
        var min = a.getMinutes();
        // if min is 1 digit, add a 0 in front
        if (min < 10) {
            min = '0' + min;
        }

        var sec = a.getSeconds();
        // if sec is 1 digit, add a 0 in front
        if (sec < 10) {
            sec = '0' + sec;
        }
        var time =  hour + ':' + min + ampm + " | " + date + ' ' + month + ' ' + year;
        return time;
    }

    
    if (loading) {
        return (
            <div class=' relative p-2 sm:p-6'>
                <div class=' relative bg-clip-border p-0 sm:p-1 border-violet-300 rounded-lg grid grid-cols-8 grid-rows-2'>
                    <div class=' col-span-8 row-span-1 rounded-t-lg'>
                        <span class='text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl'>Loading...</span>
                    </div>
                </div>
            </div>
        )
    } else {
        return (   
            // create a card with the data, the card will have a centered title, and 2 columns of data, one for new flights, one for deleted flights
            <div>
                <div class=' relative bg-clip-border p-0 sm:p-1 border-violet-300 rounded-lg'>
                    <div class='lg:flex lg:flex-row lg:justify-between'>
                        <div class='text-5xl font-extrabold '>Qantas Reward Flight Finder 🛩</div>
                        <select class='text-gray-800 border-gray-500 border-2 rounded-md' value={travelClass} onChange={e => setTravelClass(e.target.value)}>
                            {/* make PRE and FIR not selectable */}
                            <option value="PRE" disabled>PRE</option>
                            <option value="BUS">Business</option>
                            <option value="FIR" disabled>First</option>
                        </select>
                    </div>
                    {/* center the time when smalled than lg */}
                    <div class='lg:flex lg:flex-row lg:justify-between'>
                        <span class='text-md font-bold tracking-tight text-gray-900'>Last Updated: {timeConverter(timeStamp)}</span>
                    </div> 
                    <div class='lg:flex lg:flex-row lg:justify-between'>
                        <span class='text-md text-black hover:text-blue-600 cursor-pointer' onClick={() => setShowModal(true)}>Recently Found: +{newFlights.length} Flights |  -{deletedFlights.length} Flights</span>
                    </div>
                </div>
                    {showModal ? (
                        <>
                        <div
                            className="justify-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                        >
                            <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                <h3 className="text-3xl font-semibold">
                                    Added/Removed Flights
                                </h3>
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-red-400 float-right text-3xl leading-none font-semibold outline-none focus:outline-none hover:text-red-600"
                                    onClick={() => setShowModal(false)}
                                >
                                    <span>
                                    ×
                                    </span>
                                </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-12 grid grid-cols-2 items-start overflow-auto">
                                    <div className="col-span-1">
                                        <span className="text-xl font-bold">Added Flights</span>
                                        <ul>
                                            {newFlights.map((flight) => (
                                                <li className="text-md">{flight.Flight.slice(0, 3) + " -> " + flight.Flight.slice(3, 6) + " | " + timeConverter(flight.Date).split("|")[1]}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="col-span-1">
                                        <span className="text-xl font-bold">Removed Flights</span>
                                        <ul>
                                            {deletedFlights.map((flight) => (
                                                <li className="text-md">{flight.Flight.slice(0, 3) + " -> " + flight.Flight.slice(3, 6) + " | " + timeConverter(flight.Date).split("|")[1]}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                <button
                                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => setShowModal(false)}
                                >
                                    Close
                                </button>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                        </>
                    ) : null}
                </div> 
        )
    }
}



export default FlightMetaCard;


