import React from 'react';
import { useState } from 'react';

const PostList = () => {

    const ogPosts = require('./postDirectory.json');

    const[posts, setPosts] = useState(ogPosts);

    // create a function that filter posts by type
    function filterPosts(type) {
        const filteredPosts = ogPosts.filter(post => post.type === type);
        setPosts(filteredPosts);
    }

    function formatDate(dateString) {
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const ordinalSuffix = (n) => {
        if (n % 10 === 1 && n !== 11) return n + "st";
        if (n % 10 === 2 && n !== 12) return n + "nd";
        if (n % 10 === 3 && n !== 13) return n + "rd";
        return n + "th";
        };
    
        const date = new Date(dateString);
        const day = ordinalSuffix(date.getDate());
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
    
        return `${day} of ${month} ${year}`;
        
    }

    return (
        <div className="relative p-2 sm:p-16 xl:p-24">
            <div className="flex justify-between items-center">
                <h1 className="text-4xl font-bold">Posts</h1>
                <div>
                    <select
                        className="md:ml-2 text-gray-800 border-gray-500 border-2 rounded-md"
                        onChange={(e) => {
                            if (e.target.value === 'all') {
                                setPosts(ogPosts);
                            } else {
                                filterPosts(e.target.value);
                            }
                        }}
                    >
                        <option value="all">All</option>
                        <option value="ideas-tips-tricks">Ideas, Tips, and Tricks</option>
                        <option value="reviews">Reviews</option>
                        <option value="news">News</option>
                    </select>
                </div>
            </div>

            <div className="grid grid-cols-1 grid-rows-8 sm:grid-cols-2 sm:grid-rows-4 xl:grid-cols-4 xl:grid-rows-2">
                {posts.map((post) => (
                    // make the whole card a link
                    <div className="col-span-1 row-span-1 border relative clickable hover:bg-purple-100 rounded-xl m-2" onClick={() => window.location.href = "/posts/" + post.shortenedURL}>
                        <div className="absolute top-0 right-0 m-2">
                            <span className={`text-xs font-semibold py-1 px-2 uppercase rounded-full text-white ${post.type === 'ideas-tips-tricks' ? 'bg-green-600' : post.type === 'reviews' ? 'bg-purple-600' : 'bg-orange-600'}`}>
                                {/* if post.type = ideas-tips-tricks, print Ideas, Tips, and Tricks, else just print type */}
                                {post.type === 'ideas-tips-tricks' ? 'Ideas, Tips, and Tricks' : post.type}
                            </span>
                        </div>

                        <img className="object-cover w-full rounded-lg h-40 md:h-60 mb-1" src={`${process.env.PUBLIC_URL}/images/uploads/${post.featuredImage}`} alt="Feature" />
                        <h3 className="md:text-lg font-bold p-1">
                            {post.title}
                        </h3>
                        <p className="text-sm md:text-base p-1">{post.author} - {formatDate(post.date)}</p>
                        <p className="text-gray-500 text-sm md:text-base p-1 mb-1">{post.shortDescription}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PostList;
