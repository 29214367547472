import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import { Navigate } from 'react-router-dom';

const formatRelativeTime = (timestamp) => {
    const now = Date.now();
    const diff = now - timestamp * 1000;
    const minutes = Math.floor(diff / 60000);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);

    if (weeks > 0) return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
    if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
    if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    return 'Just now';
};

const NotificationRow = ({ notification }) => {
    const [expanded, setExpanded] = useState(false);

    const formatNotificationDetails = (details) => {
        try {
            const data = JSON.parse(details);
            if (data.wines) {
                return (
                    <div>
                        <p className="font-medium text-gray-900">{data.subject}</p>
                        {data.wines.map((wine, index) => (
                            <div key={index} className="mt-3 p-3 bg-gray-50 rounded">
                                <p className="font-medium text-purple-600">{wine.name}</p>
                                <div className="mt-2 grid grid-cols-2 gap-2 text-sm">
                                    <p><span className="font-medium">Price:</span> ${wine.price}</p>
                                    <p><span className="font-medium">Points:</span> {wine.points}</p>
                                    <p><span className="font-medium">Style:</span> {wine.wineStyle}</p>
                                    <p><span className="font-medium">Bottles:</span> {wine.numberOfBottles}</p>
                                    <p><span className="font-medium">Points/Dollar:</span> {wine.pointsPerDollar}</p>
                                    <p><span className="font-medium">Cents/Point:</span> {wine.centsPerPoint}</p>
                                    <p><span className="font-medium">Green Tier:</span> {wine.greenTier ? '✅' : '❌'}</p>
                                    {wine.vintage && <p><span className="font-medium">Vintage:</span> {wine.vintage}</p>}
                                </div>
                                <div className="mt-2">
                                    <a 
                                        href={wine.url} 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                        className="text-purple-600 hover:text-purple-800 text-sm"
                                    >
                                        View Wine →
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                );
            }
            // For other types of notifications, just pretty print the JSON
            return (
                <pre className="whitespace-pre-wrap bg-white p-3 rounded border">
                    {JSON.stringify(data, null, 2)}
                </pre>
            );
        } catch (e) {
            // If it's not valid JSON, return the raw details or error
            return (
                <pre className="whitespace-pre-wrap bg-white p-3 rounded border">
                    {notification.details || notification.error || 'No details available'}
                </pre>
            );
        }
    };

    return (
        <>
            <tr 
                onClick={() => setExpanded(!expanded)}
                className="cursor-pointer hover:bg-gray-50"
            >
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {formatRelativeTime(notification.timestamp)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {notification.type}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        notification.success 
                            ? 'bg-green-100 text-green-800' 
                            : 'bg-red-100 text-red-800'
                    }`}>
                        {notification.success ? 'Succeeded' : 'Failed'}
                    </span>
                </td>
            </tr>
            {expanded && (
                <tr>
                    <td colSpan="3" className="px-6 py-4 bg-gray-50">
                        <div className="text-sm text-gray-900">
                            {formatNotificationDetails(notification.details)}
                        </div>
                    </td>
                </tr>
            )}
        </>
    );
};

const NotificationsTable = ({ notifications, title }) => (
    <div className="mb-8">
        <h3 className="text-lg font-semibold mb-4">{title}</h3>
        <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                    <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Time</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {notifications.length > 0 ? (
                        notifications.map((notification, index) => (
                            <NotificationRow key={index} notification={notification} />
                        ))
                    ) : (
                        <tr>
                            <td colSpan="3" className="px-6 py-4 text-center text-sm text-gray-500">
                                No notifications found
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    </div>
);

const PreferencesSection = ({ metadata }) => {
    const formatDate = (timestamp) => {
        if (!timestamp) return 'N/A';
        return new Date(timestamp * 1000).toLocaleDateString('en-AU', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    const formatCurrency = (amount) => {
        if (!amount) return 'N/A';
        return new Intl.NumberFormat('en-AU', {
            style: 'currency',
            currency: 'AUD',
            minimumFractionDigits: 2
        }).format(amount / 100);
    };

    const sections = [
        {
            title: "Contact Information",
            items: [
                { label: "Email", value: metadata.email },
                { label: "Phone", value: `${metadata.phoneCountryCode} ${metadata.phone}` }
            ]
        },
        {
            title: "Alert Preferences",
            items: [
                { 
                    label: "Email Alerts", 
                    value: metadata.wine_alerts ? "Enabled" : "Disabled",
                    status: metadata.wine_alerts
                },
                { 
                    label: "SMS Alerts", 
                    value: metadata.sms_alerts ? "Enabled" : "Disabled",
                    status: metadata.sms_alerts
                },
                { label: "Deal Threshold", value: `${metadata.wine_threshold} cents per point` },
                { label: "Repeat Interval", value: `${metadata.same_wine_day_interval} days` }
            ]
        },
        {
            title: "Subscription Details",
            items: [
                { label: "Plan", value: metadata.subscription },
                { label: "Status", value: metadata.subscriptionStatus },
                { label: "Monthly Cost", value: formatCurrency(metadata.subscriptionPlanAmount) },
                { label: "Started", value: formatDate(metadata.subscriptionCreatedAt) },
                { label: "Renews", value: formatDate(metadata.subscriptionEndDate) },
                { 
                    label: "Cancellation", 
                    value: metadata.subscriptionCanceledAt ? formatDate(metadata.subscriptionCanceledAt) : "Not cancelled",
                    status: !metadata.subscriptionCanceledAt
                }
            ]
        }
    ];

    return (
        <div className="space-y-6">
            {sections.map((section, idx) => (
                <div key={idx} className="bg-white p-4 rounded-lg shadow-sm">
                    <h3 className="text-lg font-semibold mb-3 text-gray-800">{section.title}</h3>
                    <div className="space-y-2">
                        {section.items.map((item, itemIdx) => (
                            <div key={itemIdx} className="flex flex-col sm:flex-row sm:justify-between py-2 border-b border-gray-100 last:border-0">
                                <span className="text-sm font-medium text-gray-600">{item.label}</span>
                                {'status' in item ? (
                                    <span className={`text-sm mt-1 sm:mt-0 ${
                                        item.status 
                                            ? 'text-green-600' 
                                            : 'text-red-600'
                                    }`}>
                                        {item.value}
                                    </span>
                                ) : (
                                    <span className="text-sm mt-1 sm:mt-0 text-gray-900">{item.value}</span>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

const UserProfile = () => {
    const { id } = useParams();
    const { user: currentUser, isLoaded, isSignedIn } = useUser();
    const [loading, setLoading] = useState(true);
    const [isGod, setIsGod] = useState(false);
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        if (isLoaded && isSignedIn && currentUser) {
            checkGodStatusAndFetchData();
        }
    }, [isLoaded, isSignedIn, currentUser, id]);

    const checkGodStatusAndFetchData = async () => {
        try {
            const godCheckResponse = await fetch('https://ff-users.flightformula.workers.dev/god-check', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId: currentUser.id }),
            });

            if (!godCheckResponse.ok) {
                throw new Error('Failed to check god status');
            }

            const { isGod } = await godCheckResponse.json();
            setIsGod(isGod);

            if (isGod) {
                const userResponse = await fetch(`https://ff-users.flightformula.workers.dev/admin-user/${id}`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ userId: currentUser.id }),
                });

                if (!userResponse.ok) {
                    throw new Error('Failed to fetch user data');
                }

                const data = await userResponse.json();
                console.log('User data:', data); // Debug log
                setUserData(data.user);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error:', error);
            setLoading(false);
        }
    };

    const formatCurrency = (amount, currency = 'AUD') => {
        return new Intl.NumberFormat('en-AU', {
            style: 'currency',
            currency: 'AUD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(amount / 100);
    };

    const formatDate = (timestamp) => {
        return new Date(timestamp * 1000).toLocaleString();
    };

    if (!isLoaded || loading) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                <div className="relative">
                    <div className="mt-4 text-center">
                        <span className="text-gray-600 text-sm font-semibold">
                            Loading User Profile
                        </span>
                        <span className="inline-flex ml-2">
                            <span className="animate-[bounce_1s_infinite_200ms]">.</span>
                            <span className="animate-[bounce_1s_infinite_400ms]">.</span>
                            <span className="animate-[bounce_1s_infinite_600ms]">.</span>
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    if (!isSignedIn) {
        return <Navigate to="/login" replace />;
    }

    if (!isGod) {
        return <Navigate to="/" replace />;
    }

    if (!userData) {
        return <div className="min-h-screen bg-gray-100 flex items-center justify-center">
            <div className="text-xl text-gray-600">User not found</div>
        </div>;
    }

    return (
        <div className="min-h-screen bg-gray-100 py-8">
            <div className="max-w-4xl mx-auto px-4">
                {/* Basic Info Section */}
                <div className="bg-white rounded-lg shadow-md overflow-hidden mb-8">
                    <div className="p-6">
                        <h1 className="text-2xl font-bold mb-6">User Profile</h1>
                        
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                                <h2 className="text-lg font-semibold mb-4">Basic Information</h2>
                                <div className="space-y-3">
                                    <p><span className="font-medium">Name:</span> {userData.firstName} {userData.lastName}</p>
                                    <p><span className="font-medium">Email:</span> {userData.email}</p>
                                    <p><span className="font-medium">Created:</span> {formatDate(userData.createdAt)}</p>
                                    <p><span className="font-medium">Last Sign In:</span> {formatDate(userData.lastSignInAt)}</p>
                                </div>
                            </div>

                            <div>
                                <h2 className="text-lg font-semibold mb-4">Subscription Details</h2>
                                <div className="space-y-3">
                                    <p><span className="font-medium">Subscription:</span> {userData.subscription}</p>
                                    <p><span className="font-medium">Status:</span> {userData.subscriptionStatus}</p>
                                </div>
                            </div>
                        </div>

                        <div className="mt-8">
                            <h2 className="text-lg font-semibold mb-4">User Preferences</h2>
                            <PreferencesSection metadata={userData.metadata} />
                        </div>
                    </div>
                </div>

                {/* Billing Section */}
                {userData.stripe && (
                    <div className="bg-white rounded-lg shadow-md overflow-hidden mb-8">
                        <div className="p-6">
                            <h2 className="text-2xl font-bold mb-6">Billing Information</h2>
                            
                            {userData.stripe.subscription && (
                                <div className="mb-8">
                                    <h3 className="text-lg font-semibold mb-4">Current Subscription</h3>
                                    <div className="bg-gray-50 p-4 rounded-lg space-y-2">
                                        <p>
                                            <span className="font-medium">Plan:</span> {userData.stripe.subscription.plan.nickname || 'WinePlus'}
                                        </p>
                                        <p>
                                            <span className="font-medium">Amount:</span> {formatCurrency(userData.stripe.subscription.plan.amount)} / {userData.stripe.subscription.plan.interval}
                                        </p>
                                        <p>
                                            <span className="font-medium">Status:</span> 
                                            <span className={`ml-2 px-2 py-1 rounded-full text-sm ${
                                                userData.stripe.subscription.status === 'active' 
                                                    ? 'bg-green-100 text-green-800' 
                                                    : 'bg-red-100 text-red-800'
                                            }`}>
                                                {userData.stripe.subscription.status}
                                            </span>
                                        </p>
                                        <p>
                                            <span className="font-medium">Current Period:</span> {formatDate(userData.stripe.subscription.currentPeriodStart)} - {formatDate(userData.stripe.subscription.currentPeriodEnd)}
                                        </p>
                                        {userData.stripe.subscription.cancelAtPeriodEnd && (
                                            <p className="text-red-600">
                                                Cancels at period end: {formatDate(userData.stripe.subscription.currentPeriodEnd)}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            )}

                            {/* Payment History */}
                            <div>
                                <h3 className="text-lg font-semibold mb-4">Payment History</h3>
                                <div className="overflow-x-auto">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Receipt</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {userData.stripe.paymentHistory.map((payment) => (
                                                <tr key={payment.id}>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        {formatDate(payment.created)}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                        {formatCurrency(payment.amount, payment.currency)}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                                            payment.status === 'succeeded' 
                                                                ? 'bg-green-100 text-green-800' 
                                                                : 'bg-red-100 text-red-800'
                                                        }`}>
                                                            {payment.status}
                                                        </span>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        {payment.receipt_url && (
                                                            <a 
                                                                href={payment.receipt_url}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="text-purple-600 hover:text-purple-900"
                                                            >
                                                                View Receipt
                                                            </a>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* Notifications Section */}
                {userData.notifications && (
                    <div className="bg-white rounded-lg shadow-md overflow-hidden">
                        <div className="p-6">
                            <h2 className="text-2xl font-bold mb-6">Notification History</h2>
                            
                            {/* Email Notifications */}
                            <NotificationsTable 
                                notifications={userData.notifications.emails} 
                                title="Email Notifications" 
                            />

                            {/* SMS Notifications */}
                            <NotificationsTable 
                                notifications={userData.notifications.sms} 
                                title="SMS Notifications" 
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default UserProfile; 