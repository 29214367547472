import React, { useState, useEffect } from 'react';
import Markdown from 'markdown-to-jsx';


const PostPage = () => {
    const [post, setPost] = useState('');
    const [loading, setLoading] = useState(true);

    const postData = require('./postDirectory.json');
    const url = window.location.href;
    const postLocation = url.split('/').pop();
    const postMetadata = postData.find(post => post.shortenedURL === postLocation);
    const postLocationString = postMetadata.location;

    useEffect(() => {
        import(`../../public/posts/${postLocationString}`)
            .then(res => {
                fetch(res.default)
                    .then(res => res.text())
                    .then(res => 
                        // remove the YAML front matter from the markdown file
                        setPost(res.replace(/---[\s\S]*---/, ''))
                    )
                    .then(() => setLoading(false))
                    .catch(err => console.log(err));
            })
            .catch(err => console.log(err));
    });


    function formatDate(dateString) {
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const ordinalSuffix = (n) => {
        if (n % 10 === 1 && n !== 11) return n + "st";
        if (n % 10 === 2 && n !== 12) return n + "nd";
        if (n % 10 === 3 && n !== 13) return n + "rd";
        return n + "th";
        };
    
        const date = new Date(dateString);
        const day = ordinalSuffix(date.getDate());
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
    
        return `${day} of ${month} ${year}`;
    }

    if (loading) {
        return ( <div class='relative bg-clip-border p-16'>
        <div class='absolute top-0 left-0 w-full h-full'></div>
            <div class='relative flex flex-col items-center justify-center'>
                <div class='animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900'></div>
                <div class='text-2xl font-bold text-gray-900 mt-4'>Loading...</div>
            </div>
        </div>
        );
    } else {
        return (
            <div>
                <div className="relative h-full w-full flex items-center justify-center">
                    <article className="prose p-3 md:p-5">
                        {/* show the image */}
                        <img className="object-cover w-full rounded-lg h-28 md:h-40 mb-3" src={`${process.env.PUBLIC_URL}/images/uploads/${postMetadata.featuredImage}`} alt="Feature" />
                        {/* show the title, author, and date */}
                        <div className="text-3xl font-bold text-black">{postMetadata.title}</div>
                        <div className="text-gray-500 mb-5">{postMetadata.author} | {formatDate(postMetadata.date)}</div>
                        {/* put a line across the page */}
                        <div className="border-b-2 border-gray-300"></div>
                        {/* show the post */}
                        <Markdown>
                            {post}
                        </Markdown>
                    </article>
                </div>
            </div>
        );
    }
};

export default PostPage;