import { Link } from "react-router-dom";

const Contact = () => {
      return (
		<div className="relative p-4 md:p-32">
			<div>
				<h1 className="text-4xl font-semibold">Hello!</h1>
				<br/>
				<p>Thanks for checking out my site. If you have any enquiries, questions or comments please contact me <a href="https://www.instagram.com/flightformula/" className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-0 md:py-1 px-2 rounded-full" onClick={(e) => { e.preventDefault(); window.open("https://www.instagram.com/flightformula/", "_blank"); }}>@flightformula</a> on Instagram or email me at: <a href="mailto:flightformula@gmail.com" className="text-blue-500">flightformula@gmail.com</a></p>
				<br/>
				<p className="italic">This site is a work in progress. </p>
				<br/>
				<p>Thanks,</p>
				<p>Simon✈ <Link className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-0 md:py-1 px-2 rounded-full"onClick={() => window.open("https://www.instagram.com/simondean.xyz/", "_blank")}>@simondean.xyz</Link></p>
			</div>
		</div>
    );
}
export default Contact;