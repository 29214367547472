import { Link } from "react-router-dom";


const Tools = () => {
    return (
        // center a big button to the qfwine tracker
        <div className="flex flex-col items-center justify-center h-screen">
            <Link to="/tools/qfwine" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-10 px-20 rounded">
                QF Wine Tracker
            </Link>
        </div>
    )
}

  
export default Tools;