import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './App.css';
import Home from './pages/Home';
import PostList from './pages/PostList';
import Tools from './pages/Tools';
import Contact from './pages/Contact';
import Layout from './pages/Layout';
import QFWineTracker from './pages/QFWineTracker';
import QFFlightFinder from './pages/QFFlightFinder';
import PostPage from './pages/PostPage';
import SetupAccount from './pages/SetupAccount';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactGA from "react-ga4";
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import { ClerkProvider } from '@clerk/clerk-react';
import { SignInPage } from './components/SignInPage';
import { AccountPage } from './components/AccountPage';
import Admin from './pages/Admin';
import UserProfile from './pages/UserProfile';

// Import your publishable key
const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key")
}

export default function App() {
  ReactGA.initialize("G-HNZ5PD6RVB");
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });

  return (
    <ClerkProvider publishableKey={PUBLISHABLE_KEY} signUpForceRedirectUrl={"/setup-account"} signInForceRedirectUrl="/tools/qfwine">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="posts" element={<PostList />} />
            <Route path="tools" element={<Tools />} />
            <Route path="contact" element={<Contact />} />
            <Route path="tools/qfwine" element={<QFWineTracker />} />
            <Route path="tools/qfflightfinder" element={<QFFlightFinder />} />
            <Route path="/posts/:postId" element={<PostPage />} />
            <Route path="/tos" element={<TermsOfService />} />
            <Route path="/pp" element={<PrivacyPolicy />} />
            <Route path="signup" element={<SetupAccount />} />
            <Route path="setup-account" element={<SetupAccount />} />
            <Route path="signin" element={<SignInPage />} />
            <Route path="account" element={<AccountPage />} />
            <Route path="admin" element={<Admin />} />
            <Route path="admin/user/:id" element={<UserProfile />} />
            {/* create a catch all route that will redirect to the home page */}
            <Route path="*" element={<Home />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ClerkProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
