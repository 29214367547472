import { React, useState, useEffect } from 'react';

const FlightResults = ({searchResults}) => {

    const [ results, setResults ] = useState(searchResults);

    useEffect(() => {
        setResults(mulitOriginDetector(searchResults));
    }, [searchResults]);

    function mulitOriginDetector(results){
        var origins = {};
        // for each result, check the first 3 letters of the 'Flight' element to see if it matches the first 3 letters of any other 'Flight' element
        for (var i = 0; i < results.length; i++) {
            var origin = results[i].Flight.substring(0, 3);
            // if the origin is not already in the origins array, add it
            if (!(origin in origins)) {
                origins[origin] = [results[i]];
            }
            // if the origin is already in the origins array, add the result to the array
            else {
                origins[origin].push(results[i]);
            }
        }
        return origins;
    }

    function timeConverter(UNIX_timestamp){
        // if the unix timestamp is not a number, return the string
        console.log(UNIX_timestamp);
        if (UNIX_timestamp ==="|No flights found!") {
            return "|No flights found!😢";
        }
        var a = new Date(UNIX_timestamp * 1000);
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var ampm = "AM"
        // if hour is 1 digit, add a 0 in front
        if (hour < 10) {
            hour = '0' + hour;
        }
        // if hour is greater than 12, subtract 12 and add PM
        if (hour > 12) {
            hour = hour - 12;
            ampm = "PM"
        }
        // if hour is 12, add PM
        if (hour === 12) {
            ampm = "PM"
        }
        // if hour is 0, set to 12
        if (hour === 0) {
            hour = 12;
        }
        
        var min = a.getMinutes();
        // if min is 1 digit, add a 0 in front
        if (min < 10) {
            min = '0' + min;
        }

        var sec = a.getSeconds();
        // if sec is 1 digit, add a 0 in front
        if (sec < 10) {
            sec = '0' + sec;
        }
        var time =  hour + ':' + min + ampm + " | " + date + ' ' + month + ' ' + year;
        return time;
    }

    // if there are more than 1 origins, display each origin in a seperate card
    if (Object.keys(results).length > 1) {
        return (
            // add space between cards
            <div class='relative p-2 space-y-4'>
                    {Object.keys(results).map((origin, index) => (
                        <div key={index} class='relative bg-clip-border p-1 border-violet-500 rounded-lg grid lg:grid-cols-5 xl:grid-cols-10 border-2 grid-cols-1 grid-rows-auto'>
                            <span class='text-2xl font-bold col-span-1 lg:col-span-5 xl:col-span-10'>From: {origin}</span>
                            {results[origin].map((result) => (
                            <div class='col-span-1 row-span-1 p-2'>
                                {/* get the origin from the first 3 chars */}
                                <span class='text-xl font-bold'>{result.Flight.substring(0,3)} ➡️ {result.Flight.substring(3,6)}</span>
                                <br/>
                                {/* get the departure date for the unix timestamp, only return the data after the | */}
                                <span class='text-lg'>{timeConverter(result.Date).split("|")[1]}</span>
                            </div>
                            ))}
                        </div>
                    ))}
            </div>
        );
    }
    // if there is only 1 origin, display all the results in one card
    if (Object.keys(results).length === 1) {
        return (
            <div class=' relative p-2'>
                <div class='relative bg-clip-border p-1 border-violet-500 rounded-lg grid lg:grid-cols-5 xl:grid-cols-10 border-2 grid-cols-1 grid-rows-auto'>
                    <span class='text-2xl font-bold col-span-1 lg:col-span-5 xl:col-span-10'>Search Flights</span>
                    {results[Object.keys(results)[0]].map((result) => (
                        <div class='col-span-1 row-span-1 p-2'>
                            {/* get the origin from the first 3 chars */}
                            <span class='text-xl font-bold'>{result.Flight.substring(0,3)} ➡️ {result.Flight.substring(3,6)}</span>
                            <br/>
                            {/* get the departure date for the unix timestamp, only return the data after the | */}
                            <span class='text-lg'>{timeConverter(result.Date).split("|")[1]}</span>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}



export default FlightResults;


