import React, { useEffect, useState } from 'react';
import { useUser } from '@clerk/clerk-react';
import { Navigate } from 'react-router-dom';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip } from 'recharts';

const StatCard = ({ title, total, breakdown }) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <div 
            className="bg-white p-6 rounded-lg shadow-md cursor-pointer transition-all duration-300 hover:shadow-lg border border-gray-200"
            onClick={() => setExpanded(!expanded)}
        >
            <h2 className="text-lg font-semibold mb-2 text-gray-700">{title}</h2>
            <p className="text-3xl font-bold text-gray-800">{total}</p>
            {expanded && (
                <div className="mt-4 text-sm text-gray-600">
                    {Object.entries(breakdown).map(([key, value]) => (
                        <p key={key} className="flex justify-between items-center mt-2">
                            <span>{key}:</span>
                            <span className="font-semibold">{value}</span>
                        </p>
                    ))}
                </div>
            )}
        </div>
    );
};

const formatRelativeTime = (timestamp) => {
    const now = Date.now();
    const diff = now - timestamp * 1000;
    const minutes = Math.floor(diff / 60000);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
    if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    return 'Just now';
};

const ErrorLogRow = ({ log, hideErrorMessage = false, hideDetails = false }) => {
    const [expanded, setExpanded] = useState(false);

    const getDisplayErrorMessage = (errorMessage) => {
        if (errorMessage.includes('<TITLE>Access Denied</TITLE>')) {
            return "Tracker failed at Qantas API";
        }
        return errorMessage;
    };

    const formatOperation = (operation) => {
        return operation.split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    };

    return (
        <>
            <tr 
                className="cursor-pointer" 
                onClick={() => setExpanded(!expanded)}
            >
                <td className="border px-4 py-2">{formatOperation(log.operation)}</td>
                <td className="border px-4 py-2">{formatRelativeTime(log.unixTimestamp)}</td>
                <td className="border px-4 py-2 hidden md:table-cell">{log.userId}</td>
                {!hideDetails && (
                    <td className="border px-4 py-2 hidden md:table-cell">{log.details}</td>
                )}
                {!hideErrorMessage && (
                    <td className="border px-4 py-2">{getDisplayErrorMessage(log.errorMessage)}</td>
                )}
            </tr>
            {expanded && (
                <tr>
                    <td colSpan="5" className="border px-4 py-2 bg-gray-50">
                        <p><strong>User ID:</strong> {log.userId}</p>
                        {hideDetails && <p><strong>Details:</strong> {log.details}</p>}
                        {hideErrorMessage && <p><strong>Error Message:</strong> {getDisplayErrorMessage(log.errorMessage)}</p>}
                    </td>
                </tr>
            )}
        </>
    );
};

const ErrorLogTable = ({ logs, title, hideErrorMessage = false, hideDetails = false }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    return (
        <div className="bg-white p-6 rounded-lg shadow-md mb-8">
            <div 
                className="flex justify-between items-center cursor-pointer mb-4"
                onClick={() => setIsCollapsed(!isCollapsed)}
            >
                <h2 className="text-xl font-semibold text-gray-700">{title}</h2>
                <button className="text-gray-500 hover:text-gray-700 transition-colors">
                    {isCollapsed ? (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                        </svg>
                    )}
                </button>
            </div>
            
            <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isCollapsed ? 'max-h-0' : 'max-h-[500px]'}`}>
                <div className="overflow-x-auto">
                    <table className="min-w-full table-auto">
                        <thead>
                            <tr className="bg-gray-200">
                                <th className="px-4 py-2">Operation</th>
                                <th className="px-4 py-2">Time</th>
                                <th className="px-4 py-2 hidden md:table-cell">User ID</th>
                                {!hideDetails && (
                                    <th className="px-4 py-2 hidden md:table-cell">Details</th>
                                )}
                                {!hideErrorMessage && (
                                    <th className="px-4 py-2">Error Message</th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {logs.map((log, index) => (
                                <ErrorLogRow 
                                    key={index} 
                                    log={log} 
                                    hideErrorMessage={hideErrorMessage} 
                                    hideDetails={hideDetails}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

const TinyLineChart = ({ data, dataKey, color }) => (
    <ResponsiveContainer width="100%" height={100}>
        <LineChart data={data}>
            <XAxis dataKey="timestamp" hide={true} />
            <YAxis hide={true} />
            <Tooltip
                labelFormatter={(unixTime) => new Date(unixTime * 1000).toLocaleString()}
                formatter={(value) => [value, dataKey]}
            />
            <Line type="monotone" dataKey={dataKey} stroke={color} strokeWidth={2} dot={false} />
        </LineChart>
    </ResponsiveContainer>
);

const Pagination = ({ totalUsers, currentPage, setCurrentPage }) => {
    const totalPages = Math.ceil(totalUsers / usersPerPage);
    
    return (
        <div className="flex justify-center items-center space-x-2 mt-4">
            <button
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                className={`px-3 py-1 rounded ${
                    currentPage === 1 
                        ? 'bg-gray-200 text-gray-500 cursor-not-allowed' 
                        : 'bg-purple-600 text-white hover:bg-purple-700'
                }`}
            >
                Previous
            </button>
            <span className="text-gray-600">
                Page {currentPage} of {totalPages}
            </span>
            <button
                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                disabled={currentPage === totalPages}
                className={`px-3 py-1 rounded ${
                    currentPage === totalPages 
                        ? 'bg-gray-200 text-gray-500 cursor-not-allowed' 
                        : 'bg-purple-600 text-white hover:bg-purple-700'
                }`}
            >
                Next
            </button>
        </div>
    );
};

const usersPerPage = 100;

const PaymentIssuesTable = ({ users, onCancelSubscription }) => (
    <div className="bg-white p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-xl font-semibold text-gray-700 mb-4">Users with Payment Issues</h2>
        <div className="overflow-x-auto">
            <table className="min-w-full table-auto">
                <thead className="bg-gray-200">
                    <tr>
                        <th className="px-4 py-2 text-xs">Name</th>
                        <th className="px-4 py-2 text-xs">Email</th>
                        <th className="px-4 py-2 text-xs">Issue</th>
                        <th className="px-4 py-2 text-xs">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user) => (
                        <tr key={user.id} className="hover:bg-gray-100">
                            <td className="border px-4 py-2 text-xs">
                                {`${user.firstName} ${user.lastName}`}
                            </td>
                            <td className="border px-4 py-2 text-xs">
                                {user.email}
                            </td>
                            <td className="border px-4 py-2 text-xs">
                                <span className={`px-2 py-1 rounded-full text-xs ${
                                    user.issue === 'Cancelled' 
                                        ? 'bg-yellow-100 text-yellow-800' 
                                        : 'bg-red-100 text-red-800'
                                }`}>
                                    {user.issue}
                                </span>
                            </td>
                            <td className="border px-4 py-2 text-center">
                                <div className="flex justify-center space-x-2">
                                    <button 
                                        onClick={() => window.open(`/admin/user/${user.id}`, '_blank')}
                                        className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-1 px-2 rounded text-xs inline-flex items-center justify-center"
                                    >
                                        View
                                    </button>
                                    {user.issue !== 'Cancelled' && (
                                        <button 
                                            onClick={() => onCancelSubscription(user.id)}
                                            className="bg-red-600 hover:bg-red-700 text-white font-bold py-1 px-2 rounded text-xs inline-flex items-center justify-center"
                                        >
                                            Cancel
                                        </button>
                                    )}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>
);

const Admin = () => {
    const { user, isLoaded, isSignedIn } = useUser();
    const [isGod, setIsGod] = useState(false);
    const [loading, setLoading] = useState(true);
    const [dashboardData, setDashboardData] = useState({
        currentStats: null,
        wineTrackerStats: [],
        errorLogs: { wineTrackerErrors: [], userOperationErrors: [] },
        smsCredits: null
    });

    const [dataLoaded, setDataLoaded] = useState(false);

    const [sectionsCollapsed, setSectionsCollapsed] = useState({
        stats: false,
        charts: false,
        wineErrors: true,
        userErrors: true,
        users: false
    });

    const [currentPage, setCurrentPage] = useState(1);

    const [searchTerm, setSearchTerm] = useState('');

    const [paymentIssuesUsers, setPaymentIssuesUsers] = useState([]);
    const [analyzingPayments, setAnalyzingPayments] = useState(false);

    useEffect(() => {
        console.log('isLoaded changed:', isLoaded);
        if (isLoaded) {
            console.log('User loaded. isSignedIn:', isSignedIn, 'user:', user);
            if (isSignedIn && user) {
                checkGodStatusAndFetchData();
            } else {
                setLoading(false);
                setDataLoaded(true);
            }
        }
    }, [isLoaded, isSignedIn, user]);

    const checkGodStatusAndFetchData = async () => {
        try {
            console.log('Attempting to check god status...');
            const godCheckResponse = await fetch('https://ff-users.flightformula.workers.dev/god-check', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId: user.id }),
            });

            console.log('God check response:', godCheckResponse);

            if (!godCheckResponse.ok) {
                throw new Error(`Failed to check god status: ${godCheckResponse.status} ${godCheckResponse.statusText}`);
            }

            const { isGod } = await godCheckResponse.json();
            setIsGod(isGod);
            setLoading(false);

            if (isGod) {
                await fetchDashboardData();
            }
            setDataLoaded(true);
        } catch (error) {
            console.error('Error in checkGodStatusAndFetchData:', error);
            setLoading(false);
            setDataLoaded(true);
        }
    };

    const fetchDashboardData = async () => {
        try {
            const response = await fetch('https://ff-users.flightformula.workers.dev/admin-dashboard', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId: user.id }),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch dashboard data');
            }

            const data = await response.json();
            setDashboardData(data);
        } catch (error) {
            console.error('Error fetching dashboard data:', error);
        }
    };

    const filteredUsers = dashboardData.users ? dashboardData.users.filter(user => {
        const searchLower = searchTerm.toLowerCase();
        const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
        const email = user.email.toLowerCase();
        return fullName.includes(searchLower) || email.includes(searchLower);
    }) : [];

    const analyzePaymentIssues = async () => {
        setAnalyzingPayments(true);
        try {
            const response = await fetch('https://ff-users.flightformula.workers.dev/admin-payment-issues', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId: user.id }),
            });

            if (!response.ok) {
                throw new Error('Failed to analyze payment issues');
            }

            const data = await response.json();
            setPaymentIssuesUsers(data.users);
        } catch (error) {
            console.error('Error analyzing payment issues:', error);
        } finally {
            setAnalyzingPayments(false);
        }
    };

    const handleCancelSubscription = async (userId) => {
        if (!window.confirm('Are you sure you want to cancel this subscription? This will immediately remove their access to premium features.')) {
            return;
        }

        try {
            const response = await fetch('https://ff-users.flightformula.workers.dev/admin-cancel-subscription', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 
                    adminUserId: user.id,
                    targetUserId: userId 
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to cancel subscription');
            }

            const data = await response.json();
            if (data.success) {
                alert('Subscription cancelled successfully');
                // Remove the cancelled user from the payment issues list
                setPaymentIssuesUsers(prevUsers => 
                    prevUsers.filter(user => user.id !== userId)
                );
                // Refresh the dashboard data to show updated status
                await fetchDashboardData();
            } else {
                throw new Error(data.error || 'Failed to cancel subscription');
            }
            
        } catch (error) {
            console.error('Error cancelling subscription:', error);
            alert('Failed to cancel subscription: ' + error.message);
        }
    };

    if (!isLoaded || loading || !dataLoaded) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                <div className="relative">
                    {/* Loading text */}
                    <div className="mt-4 text-center">
                        <span className="text-gray-600 text-sm font-semibold">
                            Loading Dashboard
                        </span>
                        <span className="inline-flex ml-2">
                            <span className="animate-[bounce_1s_infinite_200ms]">.</span>
                            <span className="animate-[bounce_1s_infinite_400ms]">.</span>
                            <span className="animate-[bounce_1s_infinite_600ms]">.</span>
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    if (!isSignedIn) {
        return <Navigate to="/login" replace />;
    }

    if (!isGod) {
        return <Navigate to="/" replace />;
    }

    const { currentStats, wineTrackerStats, errorLogs, smsCredits } = dashboardData;

    return (
        <div className="bg-gray-100 min-h-screen">
            <div className="container mx-auto p-6 max-w-8xl">
                <h1 className="text-3xl font-bold mb-8 text-left text-gray-800">Admin Dashboard</h1>
                
                {wineTrackerStats.length > 0 && (
                    <div className="text-base mb-8 text-left text-gray-600">
                        <p>
                            Last Update Was: <br/> <span className="font-semibold">{Math.floor((Date.now() / 1000 - wineTrackerStats[wineTrackerStats.length - 1].timestamp) / 60)} minute{Math.floor((Date.now() / 1000 - wineTrackerStats[wineTrackerStats.length - 1].timestamp) / 60) > 1 ? 's' : ''} ago</span>
                            <br />
                            {wineTrackerStats[wineTrackerStats.length - 1].allProductsLength} products found.
                            <br />
                            {wineTrackerStats[wineTrackerStats.length - 1].historicalDataLength} wines in the historical data.
                        </p>
                    </div>
                )}
                
                {currentStats && (
                    <div className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Statistics</h2>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                            <StatCard 
                                title="Users" 
                                total={currentStats.totalUsers}
                                breakdown={{ "WinePlus": currentStats.winePlusUsers, "Free": currentStats.totalUsers - currentStats.winePlusUsers }}
                            />
                            <StatCard 
                                title="Notifications (24h)" 
                                total={currentStats.testEmails + currentStats.realEmails + currentStats.testSms + currentStats.realSms}
                                breakdown={{ 
                                    "Test Emails": currentStats.testEmails, 
                                    "Real Emails": currentStats.realEmails,
                                    "Test SMS": currentStats.testSms,
                                    "Real SMS": currentStats.realSms
                                }}
                            />
                            {smsCredits && (
                                <StatCard 
                                    title="SMS Credits" 
                                    total={smsCredits.smsCredits.toFixed(2)}
                                    breakdown={{ 
                                        "SMS Credits": smsCredits.smsCredits.toFixed(2),
                                        "MMS Credits": smsCredits.mmsCredits.toFixed(2)
                                    }}
                                />
                            )}
                        </div>
                    </div>
                )}

                {wineTrackerStats.length > 0 && (
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                        <div className="bg-white p-6 rounded-lg shadow-md">
                            <h2 className="text-xl font-semibold mb-4 text-gray-700">All Products (Last 7 Days)</h2>
                            <TinyLineChart data={wineTrackerStats} dataKey="allProductsLength" color="#8884d8" />
                        </div>
                        <div className="bg-white p-6 rounded-lg shadow-md">
                            <h2 className="text-xl font-semibold mb-4 text-gray-700">Historical Data (Last 7 Days)</h2>
                            <TinyLineChart data={wineTrackerStats} dataKey="historicalDataLength" color="#82ca9d" />
                        </div>
                    </div>
                )}

                {errorLogs.wineTrackerErrors.length > 0 && (
                    <ErrorLogTable 
                        logs={errorLogs.wineTrackerErrors} 
                        title="Wine Tracker Error Logs" 
                        hideDetails={true}
                        hideErrorMessage={false}
                    />
                )}

                {errorLogs.userOperationErrors.length > 0 && (
                    <ErrorLogTable 
                        logs={errorLogs.userOperationErrors} 
                        title="User Operation Error Logs" 
                        hideErrorMessage={true}
                        hideDetails={false}
                    />
                )}

                {/* Add Users Table */}
                {dashboardData.users && (
                    <div className="bg-white p-6 rounded-lg shadow-md mb-8">
                        <div className="flex justify-between items-center mb-4">
                            <div className="flex items-center space-x-4 flex-1">
                                <div 
                                    className="cursor-pointer"
                                    onClick={() => setSectionsCollapsed(prev => ({ ...prev, users: !prev.users }))}
                                >
                                    <h2 className="text-xl font-semibold text-gray-700">Users</h2>
                                </div>
                                <div className="flex-1 max-w-md">
                                    <input
                                        type="text"
                                        placeholder="Search by name or email..."
                                        value={searchTerm}
                                        onChange={(e) => {
                                            setSearchTerm(e.target.value);
                                            setCurrentPage(1); // Reset to first page when searching
                                        }}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                                    />
                                </div>
                            </div>
                            <button 
                                className="text-gray-500 hover:text-gray-700 transition-colors"
                                onClick={() => setSectionsCollapsed(prev => ({ ...prev, users: !prev.users }))}
                            >
                                {sectionsCollapsed.users ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                    </svg>
                                )}
                            </button>
                        </div>
                        
                        <div className={`overflow-hidden transition-all duration-300 ease-in-out ${sectionsCollapsed.users ? 'max-h-0' : 'max-h-[2000px]'}`}>
                            <div className="overflow-x-auto">
                                <div className="h-[600px] overflow-y-auto">
                                    <table className="min-w-full table-auto">
                                        <thead className="sticky top-0 bg-gray-200 z-10">
                                            <tr>
                                                <th className="hidden md:table-cell px-4 py-2 text-xs">Name</th>
                                                <th className="px-4 py-2 text-xs w-[45%] md:w-auto">Email</th>
                                                <th className="px-4 py-2 text-xs w-[35%] md:w-auto">Sub</th>
                                                <th className="px-4 py-2 text-xs w-[20%] md:w-auto">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white">
                                            {filteredUsers
                                                .slice((currentPage - 1) * usersPerPage, currentPage * usersPerPage)
                                                .map((user) => (
                                                    <tr key={user.id} className="hover:bg-gray-100">
                                                        <td className="hidden md:table-cell border px-4 py-2 text-xs">
                                                            {`${user.firstName} ${user.lastName}`}
                                                        </td>
                                                        <td className="border px-4 py-2 text-xs truncate max-w-[150px] md:max-w-none">
                                                            <div className="truncate">{user.email}</div>
                                                            <div className="text-gray-500 md:hidden truncate">{`${user.firstName} ${user.lastName}`}</div>
                                                        </td>
                                                        <td className="border px-4 py-2 text-xs truncate">
                                                            {user.subscription}
                                                        </td>
                                                        <td className="border px-4 py-2 text-center">
                                                            <button 
                                                                onClick={() => window.open(`/admin/user/${user.id}`, '_blank')}
                                                                className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-1 px-2 rounded text-xs inline-flex items-center justify-center"
                                                            >
                                                                View
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            {filteredUsers.length === 0 && (
                                                <tr>
                                                    <td colSpan="4" className="border px-4 py-2 text-center text-gray-500 text-xs">
                                                        No results found
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <Pagination 
                                    totalUsers={filteredUsers.length}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {dashboardData.users && (
                    <>
                        <div className="flex justify-center items-center mt-4 mb-8 space-x-4">
                            <button
                                onClick={analyzePaymentIssues}
                                disabled={analyzingPayments}
                                className={`bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded ${
                                    analyzingPayments ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                            >
                                {analyzingPayments ? (
                                    <span className="flex items-center">
                                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        Analyzing Payments...
                                    </span>
                                ) : (
                                    'Analyze Payment Issues'
                                )}
                            </button>
                            {!analyzingPayments && paymentIssuesUsers.length === 0 && (
                                <span className="text-green-600 font-medium animate-fade-in">
                                    We're all good, boss! 👍
                                </span>
                            )}
                        </div>
                        {paymentIssuesUsers.length > 0 && (
                            <PaymentIssuesTable 
                                users={paymentIssuesUsers} 
                                onCancelSubscription={handleCancelSubscription}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default Admin;
